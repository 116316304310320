import React, { useState } from 'react';
import { Button, Input, Affix } from 'antd'
import './App.css';
import image from "./image.png"


function App () {
const [name, setName] = useState('');
const [age, setAge] = useState('');
const [enabled, setEnabled] = useState(false);
const handleChange1 = (value) => {
  setName(value)
};

const handleChange2 = (value) => {
  setAge(value)
};

const handleSubmit = () => {
  setEnabled(true)
}

  return(
  <div>
    <div className='group'>
      <h2>"Form"</h2>
      {!enabled ? (<>
        <Input onChange={(e)=>handleChange1(e.target.value)} className='inputi' placeholder='Nome'/>
        <Input onChange={(e)=>handleChange2(e.target.value)} className='inputi' placeholder='Idade'/>
        <Button onClick={handleSubmit} className='buttoni'>"Submeter"</Button>
        </>
      ) : (
        <>
        <h4>{`O ${name} tem ${age} anos`}</h4>      
        </>
      )}     
    </div>
  </div>
  )
}


export default App;
